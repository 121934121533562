// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-doc-series-js": () => import("./../../../src/templates/docSeries.js" /* webpackChunkName: "component---src-templates-doc-series-js" */),
  "component---src-templates-guide-collection-js": () => import("./../../../src/templates/guideCollection.js" /* webpackChunkName: "component---src-templates-guide-collection-js" */)
}

